import { define } from '../redux-request';
import Api from '../../Api';

export const LOGIN = define('LOGIN');

export function loginRequest(medicalId, password, remember) {
  return LOGIN.request(() => Api.login(medicalId, password)).onSuccess((p) => {
    p.remember = remember;
    return p;
  });
}

export const CHECK_MEDICAL_ID = define('CHECK_MEDICAL_ID');

export function checkMedicalIdRequest(id) {
  return CHECK_MEDICAL_ID.request(() => Api.checkMedicalId(id));
}

export const GET_REGISTER_QUESTIONS = define('GET_REGISTER_QUESTIONS');

export function getRegisterQuestionsRequest() {
  return GET_REGISTER_QUESTIONS.request(() => Api.getRegisterQuestions());
}

export const REGISTER = define('REGISTER');

export function registerRequest(data) {
  return REGISTER.request(() => Api.register(data));
}

export const FORGOT_PASSWORD = define('FORGOT_PASSWORD');

export function forgotPasswordRequest(data) {
  return FORGOT_PASSWORD.request(() => Api.forgotPassword(data));
}

export const RESET_PASSWORD = define('RESET_PASSWORD');

export function resetPasswordRequest(data) {
  return RESET_PASSWORD.request(() => Api.resetPassword(data));
}

export const SET_REGISTER_DATA = 'SET_REGISTER_DATA';

export function setRegisterData(key, value) {
  return {
    type: SET_REGISTER_DATA,
    payload: { key, value },
  };
}

export const SET_APP_TOKEN = 'SET_APP_TOKEN';

export function setAppToken(token) {
  return {
    type: SET_APP_TOKEN,
    payload: { token },
  };
}
