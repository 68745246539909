import { GET_ORDER_RENEWALS, GET_ORDERS_LIST } from '../actions/orders';

const initialState = {
  ordersList: [],
  ordersRenewals: [],
  ordersListStatus: '',
  ordersListPages: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ORDERS_LIST.REQUEST: {
      return {
        ...state,
        ordersListStatus: 'request',
      };
    }
    case GET_ORDERS_LIST.SUCCESS: {
      const { items, totalPages } = action.payload.data;
      return {
        ...state,
        ordersListStatus: 'ok',
        ordersList: items,
        ordersListPages: totalPages,
      };
    }
    case GET_ORDERS_LIST.FAIL: {
      return {
        ...state,
        ordersListStatus: 'fail',
      };
    }

    case GET_ORDER_RENEWALS.SUCCESS: {
      const { items } = action.payload.data;
      return {
        ...state,
        ordersRenewals: items,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
