import { define } from '../redux-request';
import Api from '../../Api';

export const GET_PROFILE = define('GET_PROFILE');

export function getProfileRequest() {
  return GET_PROFILE.request(() => Api.getProfile());
}

export const UPDATE_PROFILE = define('UPDATE_PROFILE');

export function updateProfileRequest(data) {
  return UPDATE_PROFILE.request(() => Api.updateProfile(data));
}
