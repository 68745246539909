import { io } from 'socket.io-client';
import Account from '../../helpers/Account';
import { getChatMessagesRequest } from './chat';

const { REACT_APP_API_URL } = process.env;

let socket;

export const NEW_MESSAGE = 'NEW_MESSAGE';
export const USER_TYPING = 'USER_TYPING';

export function init() {
  return (dispatch) => {
    if (socket) {
      return;
    }

    const token = Account.getToken();

    socket = io.connect(REACT_APP_API_URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    socket.on('new_message', (data) => {
      dispatch({
        type: NEW_MESSAGE,
        payload: data,
      });
      dispatch(getChatMessagesRequest());
    });

    let timeout;
    socket.on('typing', () => {
      dispatch({
        type: USER_TYPING,
        payload: { typing: true },
      });
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch({
          type: USER_TYPING,
          payload: { typing: false },
        });
      }, 3000);
    });
  };
}

export const SEND_MESSAGE = 'SEND_MESSAGE';

export function sendMessage(data) {
  socket.emit('send_message', data);
  return {
    type: SEND_MESSAGE,
    payload: data,
  };
}
