import {
  GET_BRANDS,
  GET_CATEGORIES,
  GET_PRODUCTS_LIST,
  GET_PRODUCT,
  GLOBAL_SEARCH_AUTOCOMPLETE,
  SET_GLOBAL_SEARCH_VALUE,
  GET_BALANCE_TYPES,
  GET_SINGLE_RELATED_PRODUCTS,
  GET_TOP_PRODUCTS,
  ADDRESS_LIST,
} from '../actions/products';
import { ADD_TO_WISHLIST, DELETE_FROM_WISHLIST } from '../actions/wishlist';

const initialState = {
  categoriesStatus: '',
  categories: [],
  brandsStatus: '',
  brands: [],
  productsStatus: '',
  products: [],
  productsTotalPages: '',
  productsTotal: '',
  product: {},
  productStatus: '',
  autocompleteResults: [],
  autocompleteResultsStatus: '',
  globalSearchValue: '',
  balanceTypes: [],
  relatedProducts: [],
  addressList: [],
  balanceTypesStatus: '',
  productMinPrice: 1,
  productMaxPrice: 1,
  topProducts: [],
  topProductsStatus: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CATEGORIES.REQUEST: {
      return {
        ...state,
        categoriesStatus: 'request',
      };
    }
    case GET_CATEGORIES.SUCCESS: {
      const { categories } = action.payload.data;
      return {
        ...state,
        categories,
        categoriesStatus: 'ok',
      };
    }
    case GET_CATEGORIES.FAIL: {
      return {
        ...state,
        categoriesStatus: 'fail',
      };
    }
    case GET_BRANDS.REQUEST: {
      return {
        ...state,
        brandsStatus: 'request',
      };
    }
    case GET_BRANDS.SUCCESS: {
      const { brands } = action.payload.data;
      return {
        ...state,
        brands,
        brandsStatus: 'ok',
      };
    }
    case GET_BRANDS.FAIL: {
      return {
        ...state,
        brandsStatus: 'fail',
      };
    }
    case GET_PRODUCTS_LIST.REQUEST: {
      return {
        ...state,
        productsStatus: 'request',
      };
    }
    case GET_PRODUCTS_LIST.SUCCESS: {
      const {
        products, totalPages, total, lowestPrice, highestPrice,
      } = action.payload.data;
      const finalProducts = products.map((p) => {
        p._balance = state.balanceTypes.find((b) => b.id === p.balanceTypeId);
        return p;
      });
      return {
        ...state,
        products: finalProducts,
        productsStatus: 'ok',
        productsTotalPages: totalPages,
        productsTotal: total,
        productMinPrice: +lowestPrice,
        productMaxPrice: +highestPrice,
      };
    }
    case GET_PRODUCTS_LIST.FAIL: {
      return {
        ...state,
        productsStatus: 'fail',
        productsTotalPages: '',
        productsTotal: '',
      };
    }
    case ADD_TO_WISHLIST.REQUEST:
    case DELETE_FROM_WISHLIST.FAIL: {
      const { productId } = action.payload;
      const product = { ...state.product };
      const products = state.products.map((p) => {
        if (p.id === productId) {
          p.wishlists = true;
        }
        return p;
      });
      if (product.id === productId) {
        product.wishlists = true;
      }

      return {
        ...state,
        products,
        product,
      };
    }
    case DELETE_FROM_WISHLIST.REQUEST:
    case ADD_TO_WISHLIST.FAIL: {
      const { productId } = action.payload;
      const product = { ...state.product };
      const products = state.products.map((p) => {
        if (p.id === productId) {
          p.wishlists = false;
        }
        return p;
      });
      if (product.id === productId) {
        product.wishlists = false;
      }
      return {
        ...state,
        products,
        product,
      };
    }
    case GET_PRODUCT.REQUEST: {
      return {
        ...state,
        productStatus: 'request',
      };
    }
    case GET_PRODUCT.SUCCESS: {
      const { product } = action.payload.data;
      return {
        ...state,
        product,
        productStatus: 'ok',
      };
    }
    case GET_PRODUCT.FAIL: {
      return {
        ...state,
        productStatus: 'fail',
      };
    }
    case GLOBAL_SEARCH_AUTOCOMPLETE.REQUEST: {
      return {
        ...state,
        autocompleteResultsStatus: 'request',
      };
    }
    case GLOBAL_SEARCH_AUTOCOMPLETE.SUCCESS: {
      const { products } = action.payload.data;
      return {
        ...state,
        autocompleteResults: products,
        autocompleteResultsStatus: 'ok',
      };
    }
    case GLOBAL_SEARCH_AUTOCOMPLETE.FAIL: {
      return {
        ...state,
        autocompleteResultsStatus: 'fail',
      };
    }
    case GET_BALANCE_TYPES.REQUEST: {
      return {
        ...state,
        balanceTypesStatus: 'request',
      };
    }
    case GET_BALANCE_TYPES.SUCCESS: {
      const { balanceTypes } = action.payload.data;
      return {
        ...state,
        balanceTypes,
        balanceTypesStatus: 'ok',
      };
    }
    case GET_BALANCE_TYPES.FAIL: {
      return {
        ...state,
        balanceTypesStatus: 'fail',
      };
    }
    case SET_GLOBAL_SEARCH_VALUE: {
      const { value, status } = action.payload;
      let { autocompleteResultsStatus } = state;
      if (status) {
        autocompleteResultsStatus = status;
      }
      return {
        ...state,
        globalSearchValue: value,
        autocompleteResultsStatus,
      };
    }
    case GET_SINGLE_RELATED_PRODUCTS.SUCCESS: {
      const { relatedProducts } = action.payload.data;
      const finalProducts = relatedProducts.map((p) => {
        p._balance = state.balanceTypes.find((b) => b.id === p.balanceTypeId);
        return p;
      });
      return {
        ...state,
        relatedProducts: finalProducts,
      };
    }
    case GET_TOP_PRODUCTS.REQUEST: {
      return {
        ...state,
        topProductsStatus: 'request',
      };
    }
    case GET_TOP_PRODUCTS.SUCCESS: {
      const { products } = action.payload.data;
      const finalProducts = products.map((p) => {
        p._balance = [...state.balanceTypes].find((b) => b.id === p.balanceTypeId);
        return p;
      });

      return {
        ...state,
        topProductsStatus: 'ok',
        topProducts: finalProducts,
      };
    }
    case GET_TOP_PRODUCTS.FAIL: {
      return {
        ...state,
        topProductsStatus: 'fail',
      };
    }

    case ADDRESS_LIST.SUCCESS: {
      const { address } = action.payload.data;
      return {
        ...state,
        addressList: address,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
