class Account {
  static set(data) {
    localStorage.setItem('account', JSON.stringify(data));
  }

  static setToken(token, remember) {
    if (remember) {
      localStorage.setItem('token', token);
    } else {
      sessionStorage.setItem('token', token);
    }
  }

  static get() {
    try {
      const account = sessionStorage.getItem('account') || localStorage.getItem('account');
      return JSON.parse(account) || {};
    } catch (e) {
      return {};
    }
  }

  static add(data) {
    this.set({ ...this.get(), ...data });
  }

  static getToken() {
    return sessionStorage.getItem('token') || localStorage.getItem('token') || '';
  }

  static checkIsAdmin() {
    return sessionStorage.getItem('isAdmin') || '';
  }

  static getBackToAdminUrl() {
    return sessionStorage.getItem('backUrl') || '';
  }

  static delete() {
    localStorage.removeItem('token');
    localStorage.removeItem('account');

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('account');
    sessionStorage.removeItem('isAdmin');
    sessionStorage.removeItem('backUrl');
  }
}

export default Account;
