import { define } from '../redux-request';
import Api from '../../Api';

export const GET_ORDERS_LIST = define('GET_ORDERS_LIST');

export function getOrdersListRequest(data) {
  return GET_ORDERS_LIST.request(() => Api.getOrdersList({
    orderBy: 'createdAt',
    order: 'desc',
    ...data,
  })).takeLatest();
}

export const GET_ORDER_RECEIPT = define('GET_ORDER_RECEIPT');

export function getOrderReceiptRequest(orderId) {
  return GET_ORDER_RECEIPT.request(() => Api.getOrderReceipt(orderId));
}

export const CREATE_ORDER_RENEWALS = define('CREATE_ORDER_RENEWALS');

export function createOrdersRenewalsItemRequest(data) {
  return CREATE_ORDER_RENEWALS.request(() => Api.createOrdersRenewalsItem(data));
}

export const GET_ORDER_RENEWALS = define('GET_ORDER_RENEWALS');

export function getRenewalsRequest(data) {
  return GET_ORDER_RENEWALS.request(() => Api.getRenewals(data));
}

export const DELETE_ORDER_RENEWALS = define('DELETE_ORDER_RENEWALS');

export function deleteRenewalsRequest(itemIds) {
  return DELETE_ORDER_RENEWALS.request(() => Api.deleteRenewals(itemIds));
}
