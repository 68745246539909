import _ from 'lodash';
import { GET_STRIPE_INTENT, GET_PAYMENT_METHODS, GET_TRANSACTIONS } from '../actions/payment';

const initialState = {
  stripeIntent: '',
  stripeIntentStatus: '',
  paymentMethods: [],
  paymentMethodsStatus: '',
  transactions: [],
  transactionsStatus: '',
  transactionsTotalPages: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_STRIPE_INTENT.REQUEST: {
      return {
        ...state,
        stripeIntentStatus: 'request',
      };
    }
    case GET_STRIPE_INTENT.SUCCESS: {
      return {
        ...state,
        stripeIntentStatus: 'ok',
        stripeIntent: action.payload.data.intent,
      };
    }
    case GET_STRIPE_INTENT.FAIL: {
      return {
        ...state,
        stripeIntentStatus: 'fail',
        stripeIntent: '',
      };
    }
    case GET_PAYMENT_METHODS.REQUEST: {
      return {
        ...state,
        paymentMethodsStatus: 'request',
      };
    }
    case GET_PAYMENT_METHODS.SUCCESS: {
      return {
        ...state,
        paymentMethodsStatus: 'ok',
        paymentMethods: action.payload.data.cards,
      };
    }
    case GET_PAYMENT_METHODS.FAIL: {
      return {
        ...state,
        paymentMethodsStatus: 'fail',
        paymentMethods: [],
      };
    }
    case GET_TRANSACTIONS.REQUEST: {
      return {
        ...state,
        transactionsStatus: 'request',
      };
    }
    case GET_TRANSACTIONS.SUCCESS: {
      const { totalPages, transactions } = action.payload.data;
      return {
        ...state,
        transactionsStatus: 'ok',
        transactions: _.uniqBy([...state.transactions, ...transactions], 'id'),
        transactionsTotalPages: totalPages,
      };
    }
    case GET_TRANSACTIONS.FAIL: {
      return {
        ...state,
        transactionsStatus: 'fail',
        transactions: [],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
