import { combineReducers } from 'redux';
import account from './account';
import registration from './registration';
import products from './products';
import wishlist from './wishlist';
import cart from './cart';
import healthProfile from './healthProfile';
import payment from './payment';
import chat from './chat';
import orders from './orders';

export default combineReducers({
  account,
  registration,
  products,
  wishlist,
  cart,
  healthProfile,
  chat,
  payment,
  orders,
});
