import _ from 'lodash';
import {
  GET_CHAT_MESSAGES, MARK_AS_READ_MESSAGES, SEND_MESSAGES, SET_CHAT_STATUS, UNREAD_MESSAGES_COUNT,
} from '../actions/chat';
import { NEW_MESSAGE, USER_TYPING } from '../actions/socket';

const initialState = {
  chatMessages: [],
  chatMessagesStatus: '',
  chatMessagesTotalPages: '',
  chatPatients: [],
  chatPatientsStatus: '',
  unreadMessagesCount: 0,
  unreadMessagesCountStatus: '',
  userTyping: false,
  chatStatus: 'closed',
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CHAT_STATUS: {
      return {
        ...state,
        chatStatus: action.payload.status,
      };
    }
    case GET_CHAT_MESSAGES.REQUEST: {
      return {
        ...state,
        chatMessagesStatus: 'request',
      };
    }
    case GET_CHAT_MESSAGES.SUCCESS: {
      const { messages, totalPages } = action.payload.data;
      const { chatMessages } = state;
      return {
        ...state,
        chatMessagesStatus: 'ok',
        chatMessages: action.payload.more ? _.uniqBy([...chatMessages, ...messages], 'id') : messages,
        chatMessagesTotalPages: totalPages,
      };
    }
    case GET_CHAT_MESSAGES.FAIL: {
      return {
        ...state,
        chatMessagesStatus: 'fail',
      };
    }
    // case SEND_MESSAGES.SUCCESS: {
    //   const { chatMessages } = state;
    //   return {
    //     ...state,
    //     chatMessages: [...chatMessages, action.payload.data.chatMessage],
    //   };
    // }
    case UNREAD_MESSAGES_COUNT.REQUEST: {
      return {
        ...state,
        unreadMessagesCountStatus: 'request',
      };
    }
    case UNREAD_MESSAGES_COUNT.SUCCESS: {
      const { count } = action.payload.data;
      return {
        ...state,
        unreadMessagesCountStatus: 'ok',
        unreadMessagesCount: count,
      };
    }
    case UNREAD_MESSAGES_COUNT.FAIL: {
      return {
        ...state,
        unreadMessagesCountStatus: 'fail',
      };
    }
    case NEW_MESSAGE: {
      return {
        ...state,
        unreadMessagesCount: state.chatStatus === 'closed' ? state.unreadMessagesCount + 1 : state.unreadMessagesCount,
      };
    }
    case USER_TYPING: {
      return {
        ...state,
        userTyping: action.payload.typing,
      };
    }
    case MARK_AS_READ_MESSAGES.SUCCESS: {
      return {
        ...state,
        unreadMessagesCount: 0,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
