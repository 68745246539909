import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const AppPayment = React.lazy(() => import('./components/AddPaymentMethodApp'));
const Navigation = React.lazy(() => import('./Navigation'));

class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route
              path="/app/payment"
              element={(
                <Suspense fallback={<div className="loading" />}>
                  <AppPayment />
                </Suspense>
              )}
            />

            <Route
              path="/*"
              element={(
                <Suspense fallback={<div className="loading" />}>
                  <Navigation />
                </Suspense>
              )}
            />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </>
    );
  }
}

export default App;
