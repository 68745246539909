  import { define } from '../redux-request';
import Api from '../../Api';

export const GET_HEALTH_PROFILES = define('GET_HEALTH_PROFILES');

export function getHealthProfilesRequest() {
  return GET_HEALTH_PROFILES.request(() => Api.getHealthProfiles());
}

export const GET_USER_HEALTH_PROFILE = define('GET_USER_HEALTH_PROFILE');

export function getUserHealthProfileRequest() {
  return GET_USER_HEALTH_PROFILE.request(() => Api.getUserHealthProfile());
}

export const CREATE_USER_HEALTH_PROFILE = define('CREATE_USER_HEALTH_PROFILE');

export function createUserHealthProfileRequest(personalHealthProfileItems) {
  return CREATE_USER_HEALTH_PROFILE.request(() => Api.createUserHealthProfile(personalHealthProfileItems));
}
