import { GET_HEALTH_PROFILES, GET_USER_HEALTH_PROFILE } from '../actions/healthProfile';

const initialState = {
  healthProfiles: [],
  healthProfilesStatus: '',
  userHealthProfile: [],
  userHealthProfileStatus: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_HEALTH_PROFILES.REQUEST: {
      return {
        ...state,
        healthProfilesStatus: 'request',
      };
    }
    case GET_HEALTH_PROFILES.SUCCESS: {
      const { list } = action.payload.data;
      return {
        ...state,
        healthProfilesStatus: 'ok',
        healthProfiles: list,
      };
    }
    case GET_HEALTH_PROFILES.FAIL: {
      return {
        ...state,
        healthProfilesStatus: 'fail',
      };
    }
    case GET_USER_HEALTH_PROFILE.REQUEST: {
      return {
        ...state,
        userHealthProfileStatus: 'request',
      };
    }
    case GET_USER_HEALTH_PROFILE.SUCCESS: {
      const { profileList } = action.payload.data;
      return {
        ...state,
        userHealthProfileStatus: 'ok',
        userHealthProfile: profileList,
      };
    }
    case GET_USER_HEALTH_PROFILE.FAIL: {
      return {
        ...state,
        userHealthProfileStatus: 'fail',
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
