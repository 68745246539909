import { define } from '../redux-request';
import Api from '../../Api';

export const GET_CATEGORIES = define('GET_CATEGORIES');

export function getCategoriesRequest() {
  return GET_CATEGORIES.request(() => Api.getCategories());
}

export const GET_BRANDS = define('GET_BRANDS');

export function getBrandsRequest() {
  return GET_BRANDS.request(() => Api.getBrands());
}

export const GET_PRODUCTS_LIST = define('GET_PRODUCTS_LIST');

export function getProductsListRequest(params) {
  return GET_PRODUCTS_LIST.request(() => Api.getProductsList(params)).takeLatest();
}

export const GET_PRODUCT = define('GET_PRODUCT');

export function getProductRequest(id) {
  return GET_PRODUCT.request(() => Api.getProduct(id));
}

export const GLOBAL_SEARCH_AUTOCOMPLETE = define('GLOBAL_SEARCH_AUTOCOMPLETE');

export function globalSearchAutocompleteRequest(id) {
  return GLOBAL_SEARCH_AUTOCOMPLETE.request(() => Api.globalSearchAutocomplete(id)).takeLatest();
}

export const SET_GLOBAL_SEARCH_VALUE = 'SET_GLOBAL_SEARCH_VALUE';

export function setGlobalSearchValue(value, status) {
  return {
    type: SET_GLOBAL_SEARCH_VALUE,
    payload: { value, status },
  };
}

export const GET_BALANCE_TYPES = define('GET_BALANCE_TYPES');

export function getBalanceTypesRequest() {
  return GET_BALANCE_TYPES.request(() => Api.getBalanceTypes()).onSuccess(async (p) => {
    const { data: { profile: { balances } } } = await Api.getProfile();
    p.data.balanceTypes = p.data.balanceTypes.map((bal) => {
      bal.color = balances.find((b) => b.balanceTypeId === bal.id)?.color || '#ccc';
      return bal;
    });
    return p;
  });
}

export const GET_SINGLE_RELATED_PRODUCTS = define('GET_SINGLE_RELATED_PRODUCTS');

export function getSingleRelatedProductsRequest(params) {
  return GET_SINGLE_RELATED_PRODUCTS.request(() => Api.getSingleRelatedProducts(params));
}

export const CONFIRM_PRODUCTS = define('CONFIRM_PRODUCTS');

export function confirmProductItemRequest(requestData) {
  return CONFIRM_PRODUCTS.request(() => Api.confirmProductItem(requestData));
}

export const GET_TOP_PRODUCTS = define('GET_TOP_PRODUCTS');

export function productsTopRequest() {
  return GET_TOP_PRODUCTS.request(() => Api.productsTop());
}

export const ADDRESS_LIST = define('ADDRESS_LIST');

export function getAddressListRequest() {
  return ADDRESS_LIST.request(() => Api.getAddressList());
}

export const CREATE_ADDRESS_LIST = define('CREATE_ADDRESS_LIST');

export function createAddressListRequest(data) {
  return CREATE_ADDRESS_LIST.request(() => Api.createAddressList(data));
}

export const UPDATE_ADDRESS_LIST = define('UPDATE_ADDRESS_LIST');

export function updateAddressListRequest(data) {
  return UPDATE_ADDRESS_LIST.request(() => Api.updateAddressList(data));
}

export const UPDATE_ADDRESS_LIST_DEFAULT = define('UPDATE_ADDRESS_LIST_DEFAULT');

export function updateAddressListDefaultRequest(id) {
  return UPDATE_ADDRESS_LIST_DEFAULT.request(() => Api.updateAddressListDefault(id));
}

export const DELETE_ADDRESS_LIST = define('DELETE_ADDRESS_LIST');

export function deleteFromAddressRequest(id) {
  return DELETE_ADDRESS_LIST.request(() => Api.deleteFromAddress(id));
}
