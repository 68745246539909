import { define } from '../redux-request';
import Api from '../../Api';

export const GET_CHAT_MESSAGES = define('GET_CHAT_MESSAGES');

export function getChatMessagesRequest(params, more = false) {
  return GET_CHAT_MESSAGES.request(() => Api.getChatMessages({
    order: 'desc', orderBy: 'createdAt', page: 1, ...params,
  })).onSuccess((p) => {
    p.more = more;
    return p;
  });
}

export const SEND_MESSAGES = define('SEND_MESSAGES');

export function sendMessageRequest(data) {
  return SEND_MESSAGES.request(() => Api.sendMessage(data));
}

export const UNREAD_MESSAGES_COUNT = define('UNREAD_MESSAGES_COUNT');

export function unreadMessagesCountRequest() {
  return UNREAD_MESSAGES_COUNT.request(() => Api.unreadMessagesCount());
}

export const MARK_AS_READ_MESSAGES = define('MARK_AS_READ_MESSAGES');

export function markAsReadMessagesRequest() {
  return MARK_AS_READ_MESSAGES.request(() => Api.markAsReadMessages());
}

export const SET_CHAT_STATUS = 'SET_CHAT_STATUS';

export function setChatStatus(status) {
  return {
    type: SET_CHAT_STATUS,
    payload: { status },
  };
}
