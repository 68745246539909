import _ from 'lodash';
import {
  LOGIN, SET_REGISTER_DATA, GET_REGISTER_QUESTIONS, REGISTER, SET_APP_TOKEN,
} from '../actions/registration';
import Account from '../../helpers/Account';

const initialState = {
  token: Account.getToken(),
  registerData: {},
  registerQuestions: [],
  registerQuestionsStatus: '',
  registerStatus: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN.SUCCESS: {
      const { data: { token }, remember } = action.payload;
      Account.setToken(token, remember);
      return {
        ...state,
        token,
      };
    }
    case SET_APP_TOKEN: {
      const { token } = action.payload;
      Account.setToken(token);
      return {
        ...state,
        token,
      };
    }
    case SET_REGISTER_DATA: {
      const { key, value } = action.payload;
      const data = { ...state.registerData };
      return {
        ...state,
        registerData: _.set(data, key, value),
      };
    }
    case GET_REGISTER_QUESTIONS.REQUEST: {
      return {
        ...state,
        registerQuestionsStatus: 'request',
      };
    }
    case GET_REGISTER_QUESTIONS.SUCCESS: {
      return {
        ...state,
        registerQuestions: action.payload.data.questions,
        registerQuestionsStatus: 'ok',
      };
    }
    case GET_REGISTER_QUESTIONS.FAIL: {
      return {
        ...state,
        registerQuestions: [],
        registerQuestionsStatus: 'fail',
      };
    }
    case REGISTER.REQUEST: {
      return {
        ...state,
        registerStatus: 'request',
      };
    }
    case REGISTER.SUCCESS: {
      const { token } = action.payload.data;
      Account.setToken(token);
      return {
        ...state,
        registerStatus: 'ok',
        token,
      };
    }
    case REGISTER.FAIL: {
      return {
        ...state,
        registerStatus: 'fail',
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
