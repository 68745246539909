import { define } from '../redux-request';
import Api from '../../Api';

export const ADD_TO_WISHLIST = define('ADD_TO_WISHLIST');

export function addToWishlistRequest(productId) {
  return ADD_TO_WISHLIST.request(() => Api.addToWishlist(productId)).onRequest((p) => {
    p.productId = productId;
    return p;
  }).onFail((p) => {
    p.productId = productId;
    return p;
  });
}

export const DELETE_FROM_WISHLIST = define('DELETE_FROM_WISHLIST');

export function deleteFromWishlistRequest(productId) {
  return DELETE_FROM_WISHLIST.request(() => Api.deleteFromWishlist(productId)).onRequest((p) => {
    p.productId = productId;
    return p;
  }).onFail((p) => {
    p.productId = productId;
    return p;
  });
}

export const GET_WISHLIST = define('GET_WISHLIST');

export function getWishlistRequest(params) {
  return GET_WISHLIST.request(() => Api.getWishlist({
    page: 1, orderBy: 'createdAt', order: 'desc', ...params,
  }));
}
