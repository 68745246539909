import Api from '../../Api';
import { define } from '../redux-request';

export const GET_STRIPE_INTENT = define('GET_STRIPE_INTENT');

export function getStripeIntentRequest() {
  return GET_STRIPE_INTENT.request(() => Api.getStripeIntent());
}

export const ADD_PAYMENT_METHOD = define('ADD_PAYMENT_METHOD');

export function addPaymentMethodRequest(paymentMethodId) {
  return ADD_PAYMENT_METHOD.request(() => Api.addPaymentMethod(paymentMethodId));
}

export const GET_PAYMENT_METHODS = define('GET_PAYMENT_METHODS');

export function getPaymentMethodsRequest() {
  return GET_PAYMENT_METHODS.request(() => Api.getPaymentMethods());
}

export const DELETE_PAYMENT_METHOD = define('DELETE_PAYMENT_METHOD');

export function deletePaymentMethodRequest(id) {
  return DELETE_PAYMENT_METHOD.request(() => Api.deletePaymentMethod(id));
}

export const GET_TRANSACTIONS = define('GET_TRANSACTIONS');

export function getTransactionsRequest(params) {
  return GET_TRANSACTIONS.request(() => Api.getTransactions(params)).takeLatest();
}
