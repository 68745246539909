import Account from '../../helpers/Account';
import { GET_PROFILE } from '../actions/account';

const initialState = {
  profile: Account.get(),
  profileStatus: '',
  counts: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PROFILE.REQUEST: {
      return {
        ...state,
        profileStatus: 'request',
      };
    }
    case GET_PROFILE.SUCCESS: {
      const { profile, counts } = action.payload.data;
      Account.set(profile);
      return {
        ...state,
        profileStatus: 'ok',
        profile,
        counts,
      };
    }
    case GET_PROFILE.FAIL: {
      return {
        ...state,
        profileStatus: 'fail',
        profile: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
