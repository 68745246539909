import _ from 'lodash';
import { GET_CART, CHANGE_CART_ITEM_QTY } from '../actions/cart';

const initialState = {
  cart: [],
  cartStatus: '',
  total: null,
  sumOfBalanceInCart: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CART.REQUEST: {
      return {
        ...state,
        cartStatus: 'request',
      };
    }
    case GET_CART.SUCCESS: {
      const { items, total } = action.payload.data;
      const itemsByBalance = _.groupBy(items, 'product.balanceTypeId');
      const sumOfBalanceInCart = _.map(itemsByBalance, (val, id) => (
        {
          id, amount: _.sumBy(val, (o) => (+o.productItem.qty ? (+o.qty * +o.productItem.price) : 0)),
        }));
      return {
        ...state,
        cartStatus: 'ok',
        cart: items,
        total,
        sumOfBalanceInCart,
      };
    }
    case GET_CART.FAIL: {
      return {
        ...state,
        cartStatus: 'fail',
      };
    }
    case CHANGE_CART_ITEM_QTY: {
      const { itemId, qty } = action.payload;
      const cart = state.cart.map((c) => {
        if (c.id === itemId) {
          c.qty = qty;
        }
        return c;
      });

      return {
        ...state,
        cart,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
