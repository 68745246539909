import axios from 'axios';
import { serialize } from 'object-to-formdata';
import Account from './helpers/Account';
import { addToWishlistRequest } from './store/actions/wishlist';

const { REACT_APP_API_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_URL,
  'content-type': 'application/json',
});

api.interceptors.request.use((config) => {
  const token = Account.getToken('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => Promise.reject(error));

api.interceptors.response.use((res) => res, (error) => {
  if (error?.response?.status === 401) {
    Account.delete();
    window.location.href = '/';
  }
  return Promise.reject(error);
});

const apiTokens = {};

const toFormData = (data, indices = false) => serialize({ ...data }, { indices });

class Api {
  static cancel(methodName) {
    if (apiTokens[methodName]) {
      apiTokens[methodName]();
    }
  }

  static cancelToken(methodName) {
    return new axios.CancelToken((token) => {
      apiTokens[methodName] = token;
    });
  }

  static login(medicalId, password) {
    return api.post('/users/login', { medicalId, password });
  }

  static checkMedicalId(id) {
    return api.get('/users/check/medical-id', { params: { id } });
  }

  static getRegisterQuestions() {
    return api.get('/users/registration/questions');
  }

  static register(data) {
    return api.post('/users/register', data);
  }

  static getProfile() {
    return api.get('/users/profile');
  }

  static updateProfile(data) {
    return api.put('/users/profile/update', toFormData(data));
  }

  static getCategories() {
    return api.get('/products/categories');
  }

  static getBrands() {
    return api.get('/products/brands');
  }

  static getProductsList(data) {
    return api.post('/products/get/list', { page: 1, ...data });
  }

  static getProduct(id) {
    return api.get(`/products/single/${id}`);
  }

  static globalSearchAutocomplete(s) {
    this.cancel('globalSearchAutocomplete');
    return api.get(
      '/products/autocomplete',
      { params: { s }, cancelToken: this.cancelToken('globalSearchAutocomplete') },
    );
  }

  static addToWishlist(productId) {
    return api.post('/products/wishlist/create', { productId });
  }

  static deleteFromWishlist(productId) {
    return api.delete(`/products/wishlist/delete?productId=${productId}`);
  }

  static getWishlist(params) {
    return api.get('/products/wishlist', { params });
  }

  static getCart(params) {
    return api.get('/products/shopping-card', { params });
  }

  static addToCart(itemId, qty, action) {
    return api.post('/products/shopping-card/item/create', { itemId, qty, action });
  }

  static getBalanceTypes() {
    return api.get('/users/balance/types');
  }

  static deleteFromCart(itemIds) {
    return api.delete('/products/shopping-card/item/delete', { data: { itemIds } });
  }

  static getHealthProfiles() {
    return api.get('/health-profile/items');
  }

  static getUserHealthProfile() {
    return api.get('/health-profile');
  }

  static createUserHealthProfile(personalHealthProfileItems) {
    return api.post('/health-profile/create', { personalHealthProfileItems });
  }

  static getSingleRelatedProducts(params) {
    return api.get('/products/related-products', { params });
  }

  static getChatMessages(params) {
    return api.get('/chat/message/single', { params });
  }

  static sendMessage(data) {
    return api.post('/chat/message/send', data);
  }

  static confirmProductItem(requestData) {
    return api.post('/orders/create', requestData);
  }

  static unreadMessagesCount() {
    return api.get('/chat/messages/unread-count');
  }

  static markAsReadMessages() {
    return api.get('/chat/messages/seen');
  }

  static getStripeIntent() {
    return api.get('/payments/stripe/intent');
  }

  static addPaymentMethod(paymentMethodId) {
    return api.post('/payments/stripe/attach', { paymentMethodId });
  }

  static getPaymentMethods() {
    return api.get('/payments/stripe/cards');
  }

  static deletePaymentMethod(id) {
    return api.delete(`/payments/stripe/delete/card/${id}`);
  }

  static getAddressList() {
    return api.get('/address');
  }

  static createAddressList(data) {
    return api.post('/address/create', data);
  }

  static updateAddressList(data) {
    return api.put('/address/update', data);
  }

  static deleteFromAddress(id) {
    return api.delete(`/address/delete?id=${id}`);
  }

  static updateAddressListDefault(id) {
    return api.put(`/address/set/default?id=${id}`);
  }

  static getOrdersList(data) {
    return api.post('/orders/all/items', data);
  }

  static productsTop() {
    return api.get('/products/top');
  }

  static getTransactions(params) {
    return api.get('/orders/transactions', { params });
  }

  static getOrderReceipt(orderId) {
    return api.post('/orders/receive/receipt/url', { orderId });
  }

  static createOrdersRenewalsItem(data) {
    return api.post('/orders/renewals/item/create', data);
  }

  static getRenewals(data) {
    return api.get('/orders/renewals', { params: data });
  }

  static deleteRenewals(itemIds) {
    return api.delete('/orders/renewals/item/delete', { data: { itemIds } });
  }

  static forgotPassword(data) {
    return api.post('/users/forgot/password', data);
  }

  static resetPassword(data) {
    return api.post('/users/reset/password', data);
  }
}

export default Api;
