import { define } from '../redux-request';
import Api from '../../Api';

export const ADD_TO_CART = define('ADD_TO_CART');

export function addToCartRequest(itemId, qty, action = 'add') {
  return ADD_TO_CART.request(() => Api.addToCart(itemId, qty, action));
}

export const GET_CART = define('GET_CART');

export function getCartRequest(params) {
  return GET_CART.request(() => Api.getCart({
    page: 1, orderBy: 'createdAt', order: 'desc', ...params,
  }));
}

export const DELETE_FROM_CART = define('DELETE_FROM_CART');

export function deleteFromCartRequest(itemIds) {
  return DELETE_FROM_CART.request(() => Api.deleteFromCart(itemIds));
}

export const CHANGE_CART_ITEM_QTY = 'CHANGE_CART_ITEM_QTY';

export function changeCartItemQty(qty, itemId) {
  return {
    type: CHANGE_CART_ITEM_QTY,
    payload: { qty, itemId },
  };
}
