import { GET_WISHLIST } from '../actions/wishlist';

const initialState = {
  wishlist: [],
  wishlistStatus: '',
  totalPages: null,
  total: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_WISHLIST.REQUEST: {
      return {
        ...state,
        wishlistStatus: 'request',
      };
    }
    case GET_WISHLIST.SUCCESS: {
      return {
        ...state,
        wishlist: action.payload.data.wishlist,
        totalPages: action.payload.data.totalPages,
        total: action.payload.data.total,
        wishlistStatus: 'ok',

      };
    }
    case GET_WISHLIST.FAIL: {
      return {
        ...state,
        wishlist: [],
        wishlistStatus: 'fail',
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
